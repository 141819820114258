import { Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmEndpoint,
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { crmSafeListByIDs } from 'common-module/list';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { getEmptyResponse } from '~/shared/model/list/empty-response';
import { UserGroupType } from '~/api/user/groups/user-group.type';

import { MembershipType } from '../membership/membership';

import { UserCohort } from './user-cohort';
import { UserCommunity } from './user-community';
import { UserOrganization } from './user-organization';
import { UserProgram } from './user-program';

@Injectable({ providedIn: 'root' })
export class UsersGroupsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'users/groups',
  })
  protected readonly endpoint!: CrmEndpoint<UserGroupType>;

  getDefaultCommunity(
    organization: string,
  ): Observable<UserCommunity | undefined> {
    return this.listCommunitiesData({
      type: 'group',
      groupType: 'community',
      'organization._id': organization,
      default: true,
      $limit: 1,
    }).pipe(map((response) => response[0]));
  }

  getDefaultCohort(organization: string): Observable<UserCohort | undefined> {
    return this.listCohortsData({
      type: 'group',
      groupType: 'cohort',
      'organization._id': organization,
      default: true,
      $limit: 1,
    }).pipe(map((response) => response[0]));
  }

  getGroup<Response>(group: MembershipType, id: string): Observable<Response> {
    return this.endpoint.request<Response>('GET', `${group}/${id}`);
  }

  listGroup<Response>(
    group: MembershipType,
    params?: CrmDictionary,
  ): Observable<CrmEndpointListResponse<Response>> {
    return this.endpoint
      .request<CrmEndpointListResponse<Response>>('GET', group, { params })
      .pipe(catchError(() => of(getEmptyResponse<Response>())));
  }

  listGroupAll<Response>(
    group: MembershipType,
    params?: CrmDictionary,
  ): Observable<Response[]> {
    return this.listGroup(group, { ...params, limit: 20 }).pipe(
      switchMap((initialResponse) => {
        if (initialResponse.total > 20) {
          return this.listGroup(group, {
            ...params,
            skip: 20,
            limit: initialResponse.total,
          }).pipe(
            map(
              (followResponse) =>
                [...initialResponse.data, ...followResponse.data] as Response[],
            ),
          );
        }

        return of(initialResponse.data as Response[]);
      }),
    );
  }

  listOrganizations(params?: CrmDictionary): Observable<UserOrganization[]> {
    return this.listGroup<UserOrganization>('organization', params).pipe(
      map((response) => response.data),
    );
  }

  getCohort(id: string): Observable<UserCohort> {
    return this.getGroup('cohort', id);
  }

  listCohorts(
    params?: CrmDictionary,
  ): Observable<CrmEndpointListResponse<UserCohort>> {
    return this.listGroup<UserCohort>('cohort', params);
  }

  listCohortsData(params?: CrmDictionary): Observable<UserCohort[]> {
    return this.listCohorts(params).pipe(map((response) => response.data));
  }

  listCohortsSafe(
    ids: string[],
    params?: CrmDictionary,
  ): Observable<UserCohort[]> {
    return crmSafeListByIDs(
      (safeParams) => this.listCohortsData({ ...params, ...safeParams }),
      ids,
      20,
    );
  }

  listCohortsAll(params?: CrmDictionary): Observable<UserCohort[]> {
    return this.listGroupAll('cohort', params);
  }

  getCommunity(id: string): Observable<UserCommunity> {
    return this.getGroup('community', id);
  }

  listCommunities(params?: CrmDictionary) {
    return this.listGroup<UserCommunity>('community', params);
  }

  listCommunitiesData(params?: CrmDictionary) {
    return this.listCommunities(params).pipe(map((response) => response.data));
  }

  listCommunitiesSafe(ids: string[], params?: CrmDictionary) {
    return crmSafeListByIDs(
      (safeParams) => this.listCommunitiesData({ ...params, ...safeParams }),
      ids,
      20,
    );
  }

  listPrograms(
    params?: CrmDictionary,
  ): Observable<CrmEndpointListResponse<UserProgram>> {
    return this.listGroup<UserProgram>('program', {
      ...params,
      'status[$in][0]': 'published',
      'status[$in][1]': 'finished',
    });
  }

  listProgramsData(params?: CrmDictionary): Observable<UserProgram[]> {
    return this.listPrograms(params).pipe(map((response) => response.data));
  }

  listProgramsSafe(
    ids: string[],
    params?: CrmDictionary,
  ): Observable<UserProgram[]> {
    return crmSafeListByIDs(
      (safeParams) => this.listProgramsData({ ...params, ...safeParams }),
      ids,
      20,
    );
  }
}
