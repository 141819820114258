import { APP_INITIALIZER, Provider } from '@angular/core';
import { CrmModalService } from 'common-module/modal';
import { CrmMessageService } from 'common-module/message';

/**
 * Class which holds global instances of root services, main usage in decorators
 */
export class StaticHolder {
  static modal: CrmModalService;
  static message: CrmMessageService;
}

/**
 * Provider for static holder, initiates holder properties with required services
 */
export const staticHolderProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [CrmModalService, CrmMessageService],
  useFactory: (modal: CrmModalService, message: CrmMessageService) => () => {
    StaticHolder.modal = modal;
    StaticHolder.message = message;
  },
};
