import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NZ_MODAL_DATA, NzModalModule } from 'ng-zorro-antd/modal';

import { ConfirmModalData } from './confirm-modal.data';

@Component({
  standalone: true,
  selector: 'app-confirm-modal',
  template: `
    <ng-container *nzModalTitle>{{ data.title | crmTranslate }}</ng-container>
    <div>{{ data.message | crmTranslate }}</div>
    <ng-container *nzModalFooter>
      <button nz-button (click)="dismiss()">
        {{ 'generic.cancel' | crmTranslate }}
      </button>
      <button
        nz-button
        nzType="primary"
        [nzDanger]="data.danger"
        (click)="submit('success')"
      >
        {{ data.submit ?? 'generic.confirm' | crmTranslate }}
      </button>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzModalModule, NzButtonModule, CrmTranslatePipe],
})
export class ConfirmModalComponent extends CrmModalRefDirective<
  'success' | 'discard',
  ConfirmModalData
> {
  override readonly data = inject<ConfirmModalData>(NZ_MODAL_DATA);
}
