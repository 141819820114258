import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  HostBinding,
  input,
  output,
  Pipe,
  PipeTransform,
  signal,
  untracked,
  ViewEncapsulation,
} from '@angular/core';
import { crmKillEvent, CrmOmitPipe } from 'common-module/core';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmIsRenderItemPipe,
  CrmRendererDirective,
} from 'common-module/renderer';
import {
  CrmPagination,
  CrmTablePaginationComponent,
} from 'common-module/table';
import { CrmTranslatePipe } from 'common-module/translate';
import { CrmTranslateMessage } from 'common-module/translate';
import { compact, difference, get, isNil, uniq } from 'lodash-es';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import {
  CrmActionButtonsComponent,
  CrmActionButtonsConfig,
} from 'common-module/buttons';

import { IsNilPipe } from '../../pipes/is-nil.pipe';
import { FilterComponent } from '../filter/filter.component';
import { FilterField } from '../filter/filter.model';
import { TitleComponent } from '../typography/title.component';

import { TableColumn, TableHeaderRow, TableRow } from './table.model';

@Pipe({ name: 'displayColumnData', standalone: true })
export class DisplayColumnDataPipe implements PipeTransform {
  transform<Data extends TableRow>(column: TableColumn<Data>, row: Data) {
    if (column.transform) {
      return column.transform(row, get(row, column.id));
    }

    return get(row, column.id);
  }
}

@Component({
  standalone: true,
  selector: 'app-table',
  templateUrl: 'table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    NzTableModule,
    CrmTablePaginationComponent,
    DisplayColumnDataPipe,
    FilterComponent,
    CrmRendererDirective,
    CrmIsRenderItemPipe,
    NzCheckboxModule,
    IsNilPipe,
    NzIconModule,
    CrmOmitPipe,
    NzEmptyModule,
    CrmTranslatePipe,
    TitleComponent,
    CrmActionButtonsComponent,
  ],
})
export class TableComponent<Row extends TableRow> {
  @HostBinding('class')
  protected readonly hostClass = 'ehr-table';

  loading = input<boolean>(false);
  title = input<CrmTranslateMessage>();
  actionsConfig = input<CrmActionButtonsConfig>();
  titleIcon = input<string>();
  headerHidden = input<boolean>(false);
  outerBordered = input<boolean>(false);
  bordered = input<boolean>(false);
  tableClass = input<string>('');
  filters = input<FilterField[]>([]);
  filtersPlacement = input<'above' | 'afterTitle'>('above');
  columns = input<TableColumn<Row>[]>([]);
  headerRows = input<TableHeaderRow[][] | undefined>(undefined);
  pageSizeOptions = input<number[]>([10, 20, 30, 40, 50]);
  pageSizeHidden = input<boolean>(false);
  selectable = input<boolean>();
  expandable = input<boolean>(false);
  maxSelection = input<number>();
  rowClickStrategy = input<'click' | 'select'>();
  rows = input<Row[]>([]);

  pagination = input<CrmPagination | undefined>();
  filter = input<CrmDictionary>({});
  sort = input<CrmDictionary<'ascend' | 'descend'>>({});
  selected = input<string[]>([]);

  rowClick = output<string>();
  paginationChange = output<CrmPagination | undefined>();
  filterChange = output<CrmDictionary>({});
  sortChange = output<CrmDictionary<'ascend' | 'descend'>>({});
  selectedChange = output<string[]>();

  selectedRows = signal(new Set<string>());
  expandedRows = signal(new Set<string>());

  resolvedRows = computed(() =>
    this.rows().map((row) => {
      const hash = JSON.stringify(row)
        .split('')
        .reduce(
          (hash, char) =>
            char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash,
          0,
        );

      return {
        ...row,
        hash,
        isSelected: this.selectedRows().has(row.id),
        isExpanded: this.expandedRows().has(row.id),
      };
    }),
  );

  displaySelectable = computed(() => {
    const maxSelection = this.maxSelection();
    return isNil(maxSelection)
      ? (this.selectable() ?? false)
      : maxSelection > 1;
  });

  processedHeaderRows = computed<
    (TableColumn<Row> & { rowspan?: number; colspan?: number })[][]
  >(() => {
    const headerRows = this.headerRows();
    const columns = this.columns();

    if (!headerRows?.length) {
      return [columns];
    }

    return headerRows.map((row) => {
      return compact(
        row.map((hr) => {
          if (hr.columnId) {
            const col = columns.find((col) => col.id === hr.columnId);

            if (col) {
              return {
                ...col,
                rowspan: hr.rowspan,
                colspan: hr.colspan,
                cellConfig: { ...col.cellConfig, ...hr.cellConfig },
              };
            }
          }

          if (hr.id) {
            return {
              id: hr.id!,
              name: hr.name,
              rowspan: hr.rowspan,
              colspan: hr.colspan,
              cellConfig: hr.cellConfig,
            };
          }

          return null;
        }),
      );
    });
  });

  checkDisabled = computed(() => {
    const maxSelection = this.maxSelection();
    return isNil(maxSelection)
      ? false
      : maxSelection === this.selectedRows().size;
  });

  indeterminateAll = computed(() => {
    if (this.selectedRows().size === 0) {
      return false;
    }

    return this.rows().some((row) => !this.selectedRows().has(row.id));
  });

  checkedAll = computed(() => {
    if (this.selectedRows().size === 0) {
      return false;
    }

    return !this.rows().some((row) => !this.selectedRows().has(row.id));
  });

  protected readonly hasTableHeader = computed(
    () => !!(this.title() || this.actionsConfig()),
  );

  constructor() {
    effect(() => {
      const selected = this.selected();
      untracked(() => this.selectedRows.set(new Set(selected)));
    });

    effect(() => this.selectedChange.emit(Array.from(this.selectedRows())));
  }

  handleRowClick(event: MouseEvent, row: Row) {
    crmKillEvent(event);

    if (this.expandable()) {
      this.toggleExpandedRow(row);
      return;
    }

    switch (this.rowClickStrategy()) {
      case 'select':
        this.toggleSelectedRow(row);
        break;
      default:
        this.rowClick.emit(row.id);
    }
  }

  protected toggleExpandedRow(row: Row) {
    this.expandedRows.update((expanded) => {
      if (expanded.has(row.id)) {
        expanded.delete(row.id);
      } else {
        expanded.add(row.id);
      }

      return new Set(expanded);
    });
  }

  protected toggleSelectedRow(row: TableRow) {
    const maxSelection = this.maxSelection();
    const rowProperty = row.id;

    this.selectedRows.update((selected) => {
      if (selected.has(rowProperty)) {
        selected.delete(rowProperty);
      } else if (isNil(maxSelection) || selected.size < maxSelection) {
        selected.add(rowProperty);
      } else if (maxSelection === 1) {
        selected.clear();
        selected.add(rowProperty);
      }

      return new Set(selected);
    });
  }

  protected handleSort(
    order: 'ascend' | 'descend' | null,
    column: TableColumn<Row>,
  ) {
    const id = column.sortIndex ?? column.id;

    const sort = this.sort();

    if (order) {
      sort[id] = order;
    } else {
      delete sort[id];
    }

    this.sortChange.emit({ ...sort });
  }

  protected handleCheckedAll(checked: boolean) {
    const rows = this.rows().map(({ id }) => id);
    const selected = Array.from(this.selectedRows());

    if (checked) {
      this.selectedRows.set(new Set(uniq([...rows, ...selected])));
    } else {
      this.selectedRows.set(new Set(difference(selected, rows)));
    }
  }

  protected killEvent(event: MouseEvent) {
    crmKillEvent(event);
  }
}
