import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CrmLoadingComponent } from 'common-module/common';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { InfiniteScrollDirective } from '~/shared/directives/infinite-scroll.directive';

import { NotificationComponent } from './notification.component';
import { NotificationsProvider } from './notifications.provider';

@Component({
  standalone: true,
  selector: 'app-notifications',
  template: `
    @if (
      provider.isInitialized() && provider.notifications();
      as notifications
    ) {
      @if (notifications.length === 0) {
        <nz-empty></nz-empty>
      } @else {
        <div class="list" appInfiniteScroll (endScroll$)="handleEndScroll()">
          @for (notification of notifications; track notification.id) {
            <app-notification [notification]="notification"></app-notification>
          }

          @if (provider.isLoadingNotifications()) {
            <crm-loading size="small"></crm-loading>
          }
        </div>
      }
    }
  `,
  imports: [
    NotificationComponent,
    NzEmptyModule,
    NzSpinModule,
    InfiniteScrollDirective,
    CrmLoadingComponent,
  ],
  styles: [
    `
      :host {
        display: block;
        margin: 0 -16px -16px;
        min-height: 0;
        flex: 1;
      }

      .list {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 16px;
        overflow: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  protected provider = inject(NotificationsProvider);

  handleEndScroll() {
    this.provider.loadNext().subscribe();
  }
}
