import { crmGetUserName, CrmUser } from 'common-module/user';
import { DateTime } from 'luxon';

import { parseDateTime } from '~/shared/utils/parse-date-time';

import { UserModel } from './model/user.model';

export class UserResponseTransformer {
  transform(data: CrmUser): UserModel {
    const user = data as UserModel;

    if (!user.displayName) {
      user.displayName = resolveDisplayName(user);
    }

    user.age = resolveUserAge({
      birthday: user.birthday,
      death: user.dateOfDeath,
    });

    return user;
  }
}

const resolveDisplayName = (user: UserModel) => {
  if (user.firstName || user.lastName) {
    return crmGetUserName(user);
  }

  return user.username;
};

const resolveUserAge = ({
  birthday,
  death,
}: {
  birthday?: string;
  death?: string;
}) => {
  if (!birthday) {
    return undefined;
  }

  return (death ? parseDateTime(death) : DateTime.now())
    .diff(parseDateTime(birthday), ['year', 'month'])
    .get('year');
};
