import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import {
  outputFromObservable,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { CrmDictionary } from 'common-module/core/types';
import { CrmTranslatePipe } from 'common-module/translate';
import { isEqual, isNil, omitBy } from 'lodash-es';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTagModule } from 'ng-zorro-antd/tag';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  skip,
} from 'rxjs';

import { GridComponent } from '../grid/grid.component';

import { FilterField } from './filter.model';
import { FilterState, FilterStateModel, ViewModelItem } from './filter.state';

@Component({
  standalone: true,
  selector: 'app-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.less'],
  providers: [FilterState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GridComponent,
    CrmTranslatePipe,
    NzButtonModule,
    NzTagModule,
    NzSpaceModule,
    NzIconModule,
  ],
})
export class FilterComponent {
  readonly state = inject(FilterState);

  viewHidden = input<boolean>(false);
  fields = input.required<FilterField[]>();
  filter = input<CrmDictionary>({});

  private viewHidden$ = toObservable(this.viewHidden);
  private model$ = this.state.model$.pipe(debounceTime(0));

  private filter$: Observable<FilterStateModel> = this.model$.pipe(
    map((value) => {
      const filter = Object.entries(value).reduce<CrmDictionary>(
        (result, [index, data]) => {
          if (data?.data) {
            result[index] = data.data;
          }

          return result;
        },
        {},
      );

      return omitBy(filter, isNil);
    }),
    distinctUntilChanged(isEqual),
  );

  private view$ = combineLatest({
    hidden: this.viewHidden$,
    model: this.model$,
  }).pipe(
    map(({ hidden, model }) => {
      if (hidden || Object.keys(model).length === 0) {
        return { enabled: false, items: [] };
      }

      const items = Object.entries(model).reduce<
        (ViewModelItem & { track: string })[]
      >((result, [index, data]) => {
        if (data?.view) {
          result.push(
            ...data.view.map((item) => ({
              ...item,
              index,
              track: [index, item.key ?? item.value].join(';'),
            })),
          );
        }

        return result;
      }, []);

      return { enabled: items.length > 0, items };
    }),
  );

  filterChange = outputFromObservable(this.filter$.pipe(skip(1)));

  readonly viewModel = {
    fields: computed(() =>
      this.fields().map((field) => ({
        ...field,
        contentContext: { ...field.contentContext, index: field.index },
      })),
    ),
    view: toSignal(this.view$),
  };

  constructor() {
    effect(() => {
      const filter = this.filter();
      untracked(() => this.state.data.set(filter));
    });
  }
}
